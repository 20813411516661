<template>
  <b-modal
    id="logs-modal"
    hide-header
    hide-footer
    size="lg"
    centered
    @hidden="closeModal"
    @show="getLogs"
  >
    <div class="modal-header">
      <div class="modal-title">
        <span>Logs de situações</span>
      </div>
      <span class="icon-box">
        <Close class="icon" @click="closeModal" />
      </span>
    </div>
    <div v-if="loading" class="w-100 text-align-center">
        <b-spinner class="mt-5 mb-5" variant="primary"/>
      </div>
    <div class="around-content" v-else>
      <b-table
        :items="logs"
        :fields="fields"
        id="logs-table"
        show-empty
        empty-text="Nenhum log encontrado"
      >
        <template v-slot:cell(description)="data">
          <span v-html="data.item.description" />
        </template>
      </b-table>

      <b-pagination
        class="float-right mt-1 mr-4 mb-3"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="table-tiss-procedures"
      />
    </div>
    
  </b-modal>
</template>

<script>
import { parseDate, parseDateToTime } from '@/utils/dateHelper'
export default {
  name: 'LogsModal',
  components: {
    Close: () => import('@/assets/icons/close.svg')
  },
  props: {
    logId: String,
    logType: String
  },
  data: () => ({
    logs: [],
    loading: false,
    currentPage: 1,
    perPage: 20,
    rows: 0,
    fields: [
      {
        key: 'date_time',
        label: 'Data',
        thStyle: { width: '15%' }
      },
      {
        key: 'time',
        label: 'Horário',
        thStyle: { width: '15%' }
      },
      {
        key: 'user.name',
        label: 'Responsável',
        thStyle: { width: '25%' }
      },
      {
        key: 'description',
        label: 'Descrição',
        thStyle: { width: '45%' }
      }
    ]
  }),
  methods: {
    parseDate,
    parseDateToTime,
    async getLogs() {
      this.loading = true
      try {
        const { data } = await this.api.getLogs(
          this.logType,
          this.logId,
          this.currentPage
        )
        this.logs = data.data.map(log => {
          return {
            ...log,
            date_time: parseDate(log.date_time),
            time: parseDateToTime(log.date_time)
          }
        })

        this.currentPage = data.current_page
        this.perPage = data.per_page
        this.rows = data.total
      } catch (error) {
        this.$toast.error('Erro ao buscar logs')
      } finally {
        this.loading = false
      }
    },
    closeModal() {
      this.$bvModal.hide('logs-modal')
      this.logs = []
    }
  },
  watch: {
    async currentPage() {
      await this.getLogs()
    }
  }
}
</script>

<style lang="scss">
#logs-modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }
  }

  .around-content {
    padding: 24px 24px 0 24px;
    table {
      width: 100%;

      thead {
        background: var(--neutral-200);
        tr {
          padding: 8px 8px 0 0;
        }
        th {
          color: var(--type-active);
          padding: 10px;
          border: 1px solid var(--neutral-200);
        }
      }

      tbody {
        tr {
          border: 1px solid var(--neutral-200);
          td {
            padding: 10px 0 10px 10px;
            color: var(--type-active);
            font-weight: 500;
            max-width: 40ch;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
</style>